/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
body {
  line-height: 1 !important;
  background-color: #f1f1f1 !important;
}

.modal-body {
  line-height: 20px !important;
}

.nm-l {
  margin-left: -10px !important;
}

.sub-header {
  width: 100%;
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.45);
}

.checkbox-group {
  padding: 5px;
  line-height: 25px;
}

.table td,
.table th {
  border-top: 0 !important;
  border-bottom: 1px solid #f1f1f1;
}
.table th {
  background-color: #f3f2f2 !important;
  border-bottom: 0 !important;
  color: #737373 !important;
  padding: 0.75rem;
}
.sub-header h2 {
  font-weight: 400;
  font-size: 20px;
  color: #495057;
}

app-root {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}
.eps-container {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: auto;
}
.eps-nav {
  padding: 15px 0 !important;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(41, 41, 41, 0.25);
  -moz-box-shadow: 0px 0px 5px 1px rgba(41, 41, 41, 0.25);
  box-shadow: 0px 0px 5px 1px rgba(41, 41, 41, 0.25);
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.remove-left-padding {
  padding-left: 0 !important;
}

.survey-content {
  padding: 25px 15px;
}

.svd_container.sv_default_css .modal .modal-dialog {
  margin: 60px auto !important;
}

.error-msg {
  color: #a94442 !important;
  background-color: #f2dede !important;
  border-color: #ebccd1;
  padding: 15px;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
}

.form-error-msg {
  color: #dc3545;
  font-size: 80%;
}

/* margin and padding utility classes */
.mr-auto {
  margin-right: auto;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.h-content {
  height: fit-content !important;
}

/* FLOATING LABELS FOR LOGIN */

.form-signin {
  max-width: 420px;
  padding: 15px;
  margin: auto;
  margin-top: 15%;
  width: 100%;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

.capitalize-input {
  text-transform: capitalize;
}

.uppercase-input {
  text-transform: uppercase;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: var(--bs-secondary);
}

/* Fallback for Edge
  -------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
  -------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

/* modal animation */
.modal-content {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* SKELETON */
.empty,
.empty-header,
.empty-button,
.empty-text,
.empty-image,
.empty-tab-link,
.empty-header-2,
.empty-icon,
.empty-list-item,
.empty-user-profile-image,
.empty-list-item-sort {
  animation: loading 1.5s infinite linear;
  background: #dedfe1;
  background-image: -webkit-linear-gradient(
    to right,
    #dedfe1 0%,
    #f2f3f5 20%,
    #dedfe1 40%,
    #dedfe1 100%
  );
  background-image: linear-gradient(
    to right,
    #dedfe1 0%,
    #f2f3f5 20%,
    #dedfe1 40%,
    #dedfe1 100%
  );
  background-repeat: no-repeat;
  margin: 5px 0;
}

@keyframes loading {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 800px;
  }
}

@keyframes initial-animation {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.animate-dropdown {
  overflow: hidden;
  transform-origin: top;
  animation: initial-animation 0.1s cubic-bezier(0, 0.55, 0.45, 1) forwards;
  animation-iteration-count: 1;
}
.empty {
  height: 25px;
  width: 100%;
}

.empty-header {
  height: 65px;
  width: 125px;
  display: inline-block;
}

.empty-header-2 {
  height: 45px;
  width: 125px;
  display: inline-block;
}

.empty-text {
  height: 25px;
  width: 225px;
  display: block;
}

.empty-tab-link {
  height: 35px;
  width: 100%;
  display: block;
}

.empty-button {
  height: 45px;
  width: 125px;
  display: inline-block;
  margin-left: 10px;
}

.empty-image {
  height: 115px;
  width: 150px;
}

.empty-user-profile-image {
  height: 75px;
  width: 50px;
}

.empty-icon {
  height: 16px;
  width: 25px;
}

.empty-list-item {
  height: 18px;
  width: 100%;
}

.empty-list-item-sort {
  height: 18px;
  width: 80%;
}

/* initial avatar classes */

.light-green {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.85);
}

.blue-grey {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.85);
}

.indigo {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.85);
}

.pink {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.85);
}
.brown {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.85);
}

.grey {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.85);
}

.teal {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.85);
}

.cyan {
  background-color: #00bcd4;
  color: rgba(255, 255, 255, 0.85);
}

.light-blue {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.85);
}

/* USER Avatar CSS*/
.user-inital-avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.circle {
  border-radius: 500px;
}

.w-40 {
  width: 40px;
  height: 40px;
}

.user-inital-avatar i.active {
  background-color: #22b66e;
}

.user-inital-avatar i.inactive {
  background-color: #929292;
}

.user-inital-avatar i.avatar-right {
  left: auto;
  top: 0;
  right: 0;
}

.user-inital-avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  border-color: #fff;
  background-color: #fff;
}

.b-white {
  border-color: #fff;
}

.no-arrow::after {
  display: none !important;
}

.sortable-container .sortable-list .active {
  background: transparent !important;
  color: black !important;
}

.sortable-list-item {
  margin-top: 10px !important;
}

.sortable-container {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  border-radius: 0px !important;
}

.no-data-loaded {
  width: 400px;
  margin: 0 auto;
  margin-top: 15%;
}

.help-text {
  color: #969696;
  display: inline-block;
}

/* Override for the default bootstrap styles */
/* TODO: customize the bootstrap styles using _variables.scss */
.list-group .list-group-item {
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: 0.75rem;
}

.btn-link,
a {
  text-decoration: none;
}

.form-control {
  border-radius: 0.25rem;
}

.btn {
  --bs-btn-border-radius: 0.25rem;
}

.btn-link:hover,
.breadcrumb-item > a:hover {
  text-decoration: underline;
}

:root {
  --bs-dark-rgb: 52, 58, 64;
  --bs-border-radius: 0.25rem;
  --hover-light: #f8f9fa;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.navbar {
  --bs-navbar-padding-x: 1rem;
}

/* Custom styles for SurveyJS */
:root {
  --base-unit: 7px;
  --primary: #2196f3;
  --sd-base-padding: calc(4 * var(--base-unit, 8px));
  --sd-base-vertical-padding: calc(3 * var(--base-unit, 8px));
  --sd-page-vertical-padding: calc(2 * var(--base-unit, 8px));
  --sv-element-add-padding-left: calc(2 * var(--base-unit, 8px));
  --sjs-font-surveytitle-weight: 300;
}
.sd-root-modern {
  --sd-base-padding: calc(2 * var(--base-unit, 8px));
  --sd-base-vertical-padding: calc(2 * var(--base-unit, 8px));
  --sd-page-vertical-padding: calc(2 * var(--base-unit, 8px));
}

// .sd-header__text .sd-title {
//   color: var(--sjs-primary-backcolor, var(--primary, #19b394));
//   font-family: var(--sjs-font-surveytitle-family, var(--font-family));
//   font-size: var(--sjs-font-surveytitle-size, calc(2 * var(--sjs-font-size, var(--sjs-font-size, calc(2 * var(--base-unit, 8px))))));
//   font-weight: var(--sjs-font-surveytitle-weight, 700);
// }

// for sizing avatars
.size-15 {
  width: 1.5em;
  height: 1.5em;
  font-weight: 500;
}
.size-24 {
  width: 24px;
  height: 24px;
}

.size-150 {
  width: 150px;
  height: 150px;
  font-size: 3.3rem !important;
}

.btn-tools-wrapper {
  position: absolute;
  z-index: 10;
  right: 0;
  background-color: white;
  box-shadow: 0 3px 6px rgb(187, 187, 187);
  border: 1px solid #ebecf0;
  border-radius: 0 0 3px 3px;
  margin-top: 0.25rem !important;
  padding: 0.25rem;
  gap: 0.25rem;
  display: flex;
  float: right;
}

::-webkit-scrollbar {
  height: 1rem;
  width: 0.5rem;
  &::-webkit-scrollbar:hover {
    width: 1rem;
    transition: all 0.15s ease-in-out;
  }
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: rgba(155, 155, 155, 0.5);
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: rgba(155, 155, 155, 0.8);
    width: 1rem;
    transition: all 0.15s ease-in-out;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
  padding: 0 0.5rem;
}

.active-tab-border {
  position: absolute;
  background-color: var(--bs-nav-link-color, #0d6efd);
  transition: all 0.2s;
  bottom: 0;
}
